/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;500;600;700&family=Inter:wght@400;500;600;700&family=JetBrains+Mono:wght@400;500&display=swap');

:root {
  --font-serif: 'Source Serif Pro', Georgia, 'Times New Roman', serif;
  --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  --font-mono: 'JetBrains Mono', monospace;
}

/* Base typography */
body {
  font-family: var(--font-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Headers and important text */
h1, h2, h3, h4, h5, h6,
.stock-symbol,
.login-header h2,
.subscription-header h2,
.plan-name,
.similar-stocks-header {
  font-family: var(--font-serif);
  font-weight: 600;
  letter-spacing: -0.02em;
}

/* Navigation */
.topnav ul li a {
  font-family: var(--font-sans);
  font-weight: 500;
}

/* Numeric data */
.stock-price,
.value,
.numeric-data,
.plan-price,
table td[data-type="numeric"] {
  font-family: var(--font-mono);
  font-feature-settings: "tnum" on, "lnum" on;
}

/* Descriptions and secondary text */
.disclaimer-text,
.plan-commitment,
.plan-savings,
.copyright-text {
  font-family: var(--font-sans);
  font-size: 0.875rem;
  line-height: 1.5;
}

.topnav {
  background-color: #1a56db;
  padding: 0.75rem 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.topnav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
}

.topnav ul li {
  margin-right: 1rem;
}

.topnav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.topnav ul li a:hover {
  color: #bfdbfe;
}

.main-content {
  padding: 2rem;
}

/* Remove underlines from links and set hover color */
a {
  text-decoration: none;
}

a:hover {
  color: #2563eb; /* Tailwind's blue-600 */
}

/* Specific style for stock list links if needed */
.stock-list a {
  color: inherit;
}

.stock-list a:hover {
  color: #2563eb; /* Tailwind's blue-600 */
}

.popover-content {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
}input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  height: 8px; /* Match the height of your original slider */
}

/* Track styles */
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #e5e7eb; /* Tailwind gray-200 */
  border-radius: 0.5rem;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #e5e7eb;
  border-radius: 0.5rem;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #3B82F6;
  cursor: pointer;
  margin-top: -4px; /* Centers the thumb on the track */
}

input[type='range']::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #3B82F6;
  cursor: pointer;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.tooltip {
  background-color: #f8f9fa;
  border: 2px solid #d1d5db;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* In your master CSS file */
.custom-tooltip {
  background-color: white !important;
  border: 2px solid #ccc !important;
  border-radius: 8px !important;
  padding: 12px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.custom-tooltip p {
  margin: 0;
  line-height: 1.5;
}

.custom-tooltip .symbol {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 8px;
}

.custom-tooltip .text-red-500 {
  color: #ef4444;
}

.custom-tooltip .text-sm {
  font-size: 0.875rem;
}

.custom-tooltip .mt-1 {
  margin-top: 0.25rem;
}

.custom-tooltip .ml-2 {
  margin-left: 0.5rem;
}

/* Add these to your existing App.css file */

/* Similar Stocks Table Styles */
.similar-stocks-table {
  margin-top: 2rem;
  overflow-x: auto;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.similar-stocks-table table {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.similar-stocks-table thead tr {
  background-color: #f3f4f6;
}

.similar-stocks-table th {
  padding: 0.75rem 1.5rem;
  border-bottom: 2px solid #e5e7eb;
  text-align: right;
  font-size: 0.75rem;
  font-weight: 600;
  color: #4b5563;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  white-space: nowrap;
}

.similar-stocks-table th:first-child {
  text-align: left;
}

.similar-stocks-table tbody tr {
  border-bottom: 1px solid #e5e7eb;
}

.similar-stocks-table tbody tr:hover {
  background-color: #f9fafb;
}

.similar-stocks-table tbody tr.focus-stock {
  background-color: #eff6ff;
}

.similar-stocks-table td {
  padding: 1rem 1.5rem;
  text-align: right;
  white-space: nowrap;
}

.similar-stocks-table td:first-child {
  text-align: left;
  font-weight: 500;
}

.similar-stocks-table .value {
  font-weight: 500;
  color: #111827;
}

.similar-stocks-table .percentage-change {
  font-size: 0.875rem;
  margin-left: 0.25rem;
}

.similar-stocks-table .percentage-up {
  color: #059669;
}

.similar-stocks-table .percentage-down {
  color: #dc2626;
}

.similar-stocks-table .similarity-score {
  font-weight: 500;
  color: #111827;
}

/* Table Header */
.similar-stocks-header {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* Table Container */
.similar-stocks-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  overflow-x: auto;  /* Add this */
  -webkit-overflow-scrolling: touch;  /* Add this */
}


/* Divide rows */
.similar-stocks-table tbody tr:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}

/* Specific element styling */
.stock-symbol {
  font-family: var(--font-secondary);
  font-weight: 600;
}

.stock-price {
  font-family: var(--font-mono);
  font-feature-settings: "tnum" on, "lnum" on;  /* For better number alignment */
}

.chart-title {
  font-family: var(--font-primary);
  font-weight: 500;
}

.data-table {
  font-family: var(--font-primary);
}

.data-table .numeric {
  font-family: var(--font-mono);
}

.stock-label {
  font-weight: 700 !important;
}

a:visited {
  color: inherit;
}

/* or more specifically for your stock links */
.text-blue-500:visited {
  color: rgb(59, 130, 246); /* this is the Tailwind blue-500 color */
}

/* if you want to ensure hover still works */
.text-blue-500:visited:hover {
  color: rgb(29, 78, 216); /* this is the Tailwind blue-700 color */
}

/* Add these styles to your CSS file */
.similar-stocks-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.similar-stocks-header {
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 1px solid #e5e7eb;
}

.similar-stocks-table {
  overflow-x: auto;
}

.similar-stocks-table table {
  width: 100%;
  border-collapse: collapse;
}

.similar-stocks-table th {
  text-align: left;
  padding: 1rem;
  font-weight: 600;
  color: #4b5563;
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}

.similar-stocks-table td {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.similar-stocks-table tr:last-child td {
  border-bottom: none;
}

.value {
  font-weight: 500;
  color: #111827;
}

.similar-stocks-table tr:hover {
  background-color: #f9fafb;
}

/* Score cell styling */
.score-high {
  background-color: #dcfce7;
}

.score-medium {
  background-color: #dbeafe;
}

.score-low {
  background-color: #fef9c3;
}

.score-poor {
  background-color: #fee2e2;
}

/* Percentile cell styling */
.percentile-excellent {
  background-color: #dcfce7;
}

.percentile-good {
  background-color: #dbeafe;
}

.percentile-average {
  background-color: #fef9c3;
}

.percentile-below {
  background-color: #fee2e2;
}

/* Icon styling */
.metric-icon {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.metric-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* Value styling */
.value {
  font-weight: 500;
}

/* Make sure the last row doesn't have a border */
.similar-stocks-table tr:last-child td {
  border-bottom: none;
}

/* Remove hover effect as it might interfere with cell colors */
.similar-stocks-table tr:hover {
  background-color: inherit;
}

/* Row gradient styling */
.percentile-row {
  transition: background-color 0.2s ease;
}

/* Value styling */
.value {
  font-weight: 500;
  color: #1a1a1a;
}

/* Icon styling */
.metric-icon {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.metric-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
.topnav .nav-content ul {
  display: flex;
  align-items: center;
}

.topnav .nav-content ul li:last-child {
  margin-left: auto;
}

.topnav {
  position: relative;  /* Add this */
}

.nav-content {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

.nav-content ul {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.topnav {
  height: 40px;  /* or whatever height you want */
  position: relative;
  z-index: 100;
}

.nav-content {
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-content ul {
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-content .flex {
  display: flex;
  align-items: center;
}

.topnav ul li a {
  color: white;
  font-weight: 500;
  font-size: 0.925rem;
  padding: 0.5rem 0;
  transition: color 0.2s;
}

.topnav ul li a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.stock-search input {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.stock-search input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.stock-search input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
}

/* Account section styling */
.account-section {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  transition: background-color 0.2s;
}

.account-section:hover {
  background: rgba(255, 255, 255, 0.15);
}

.account-section .user-email {
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
}

.account-section .sign-out-btn {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.2s;
}

.account-section .sign-out-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Login Page Styles */
.login-container {
  min-height: 100vh;
  background: linear-gradient(to bottom, #f0f5ff, #ffffff);
  padding: 2rem;
  font-family: var(--font-primary);
}

.login-card {
  max-width: 32rem;
  margin: 2rem auto;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
}

.login-header h2 {
  font-family: var(--font-secondary);
  font-size: 2rem;
  font-weight: 600;
  color: #1a56db;
  margin-bottom: 0.5rem;
}

.login-header p {
  color: #4b5563;
  font-size: 1rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #374151;
  font-size: 0.875rem;
}

.form-group input {
  padding: 0.75rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.2s;
}

.form-group input:focus {
  outline: none;
  border-color: #1a56db;
  box-shadow: 0 0 0 3px rgba(26, 86, 219, 0.1);
}

/* Normalized button styles */
.login-button, .trial-button {
  display: flex;  /* Changed to flex for better centering */
  justify-content: center;  /* Horizontal center */
  align-items: center;     /* Vertical center */
  width: auto;
  min-width: 200px;
  max-width: 300px;
  margin: 0.5rem auto;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  height: 40px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.login-button {
  background-color: #1a56db;  /* Your existing blue */
  color: white;
}

.login-button:hover {
  background-color: #1e40af;
}

.trial-button {
  font-weight: 600;  /* Bolder text */
  font-size: 1rem;   /* Larger font size */
  background-color: #10b981;
  color: white;
  text-decoration: none;
}

.trial-button:hover {
  background-color: #059669;
  transform: none;  /* Remove transform animation to match sign-in */
}

/* Remove any leftover margin between buttons */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;  /* Consistent spacing between all elements */
}

.disclaimer-section {
  margin-top: 2rem;
  background: white;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.disclaimer-title {
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.disclaimer-text {
  color: #4b5563;
  font-size: 0.75rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
}

.copyright-text {
  color: #6b7280;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 1rem;
}

.subscription-container {
  min-height: 100vh;
  background: linear-gradient(to bottom, #f0f5ff, #ffffff);
  padding: 2rem;
  font-family: var(--font-primary);
}

.subscription-card {
  max-width: 64rem;
  margin: 2rem auto;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.subscription-header {
  text-align: center;
  margin-bottom: 2rem;
}

.subscription-header h2 {
  font-family: var(--font-secondary);
  font-size: 2rem;
  font-weight: 600;
  color: #1a56db;
  margin-bottom: 0.5rem;
}

.subscription-header p {
  color: #4b5563;
  font-size: 1rem;
}

.subscription-plans {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.plan-card {
  background: white;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1;
  transition: transform 0.2s ease;
}

.plan-card:hover {
  transform: scale(1.02);
}

.plan-card-recommended {
  border: 2px solid #1a56db;
}

.plan-label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #1a56db;
  margin-bottom: 1rem;
}

.plan-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
}

.plan-price {
  font-size: 1.25rem;
  font-weight: 500;
  color: #4b5563;
}

.plan-commitment, .plan-savings {
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.subscription-button {
  display: inline-block;
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 500;
  background-color: #1a56db;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 1.5rem;
}

.subscription-button:hover {
  background-color: #1e40af;
}

.trial-details {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.875rem;
  color: #6b7280;
}

/* Dropdown Menu Styling */
.dropdown-menu-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 0.5rem;
  min-width: 220px;
  margin-top: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  animation: dropdownFadeIn 0.2s ease;
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-menu-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  margin: 0.25rem;
  border-radius: 6px;
  color: #374151;
  font-size: 0.875rem;
  transition: all 0.15s ease;
  cursor: pointer;
  user-select: none;
}

.dropdown-menu-item:hover {
  background-color: #f3f4f6;
}

.dropdown-menu-item:active {
  background-color: #e5e7eb;
}

.dropdown-menu-separator {
  height: 1px;
  background-color: #e5e7eb;
  margin: 0.5rem;
}

.dropdown-menu-signout {
  color: #dc2626;
}

.dropdown-menu-signout:hover {
  background-color: #fef2f2;
}

/* Nav Icon Button */
.nav-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  background: transparent;
  transition: all 0.2s ease;
  color: white;
}

.nav-icon-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-icon-button:active {
  transform: scale(0.95);
}

/* Account Section Styles */
.account-section {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.user-email {
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
}

/* Mobile Responsive Styles */
@media (max-width: 640px) {
  .dropdown-menu-content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 12px 12px 0 0;
    animation: slideUp 0.3s ease;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
}

/* Additional Nav Styles */
.topnav {
  background-color: #1a56db;
  padding: 0.75rem 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-content {
  max-width: 1280px;
  margin: 0 auto;
}

.nav-content ul {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-content ul li a {
  color: white;
  font-weight: 500;
  font-size: 0.925rem;
  padding: 0.5rem 0;
  transition: color 0.2s;
}

.nav-content ul li a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.success-message {
  background-color: #dcfce7;
  color: #166534;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.error-message {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

/* Orientation handling styles */
.orientation-warning {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 300px;
  z-index: 9999;
  text-align: center;
  background: white;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.orientation-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);  /* Much more transparent */
  backdrop-filter: blur(1.5px);
}

.orientation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orientation-content h2 {
  font-size: 1.5rem;
  margin: 1.5rem 0 0.5rem;
  font-weight: 600;
}

.orientation-content {
  position: relative;
  max-width: 320px;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  color: #1a1a1a;
}

.phone-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  color: #1a56db;
}

.phone-icon svg {
  width: 40px;
  height: 40px;
}


.dismiss-button {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: #6b7280;
  transition: all 0.2s;
  cursor: pointer;
}

.dismiss-button:hover {
  color: #1f2937;
  background: #f3f4f6;
}

.rotate-arrow {
  animation: rotateArrow 2s infinite;
}

@keyframes rotateArrow {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(-90deg); }
  100% { transform: rotate(0deg); }
}

/* Force landscape mode using CSS */
@media screen and (orientation: portrait) {
  /* This will rotate the entire viewport */
  .app-container {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

/* Add specific mobile landscape optimizations */
@media screen and (orientation: landscape) and (max-height: 500px) {
  .topnav {
    height: 40px;
  }

  .main-content {
    margin-top: 40px;
  }

  .nav-items {
    gap: 1rem;
  }
}

/* Adjust for very small screens */
@media screen and (max-height: 400px) {
  .orientation-warning {
    padding: 1rem;
  }

  .phone-icon svg {
    width: 32px;
    height: 32px;
  }

  .orientation-warning h2 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .similar-stocks-header {
    padding: 0.75rem;
    font-size: 1rem;
  }

  .similar-stocks-table th,
  .similar-stocks-table td {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .value {
    font-weight: 500;
    font-size: 0.875rem;
  }

  .metric-icon {
    gap: 0.25rem;
  }

  .metric-icon svg {
    width: 16px;
    height: 16px;
  }
}

/* For better touch interaction */
@media screen and (max-width: 640px) {
  .main-content {
    padding: 0.5rem;  /* Reduced from 2rem */
  }

  /* Ensure text doesn't overflow on mobile */
  h2 {
    font-size: 1.25rem;
    line-height: 1.4;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  /* Adjust grid gaps */
  .grid {
    gap: 0.5rem;
  }
}

/* Improve table scrolling experience */
.similar-stocks-table::-webkit-scrollbar {
  height: 4px;
}

.similar-stocks-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.similar-stocks-table::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

@media screen and (max-width: 640px) {
  /* Adjust nav height and padding */
  .topnav {
    padding: 0.5rem;
    height: auto;
  }

  /* Make nav content stack better */
  .nav-content {
    padding: 0;
  }

  /* Adjust list items */
  .nav-content ul {
    gap: 0.5rem;
    flex-wrap: wrap;  /* Allow items to wrap */
    justify-content: flex-start;
  }

  /* Make nav links more compact */
  .nav-content ul li a {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
  }

  /* Make CANSLIM text shorter */
  .nav-content ul li a[href="/canslim-stocks"] {
    font-size: 0;  /* Hide full text */
  }
  .nav-content ul li a[href="/canslim-stocks"]::before {
    content: "CANSLIM";
    font-size: 0.75rem;
  }

  /* Adjust search positioning */
  .nav-content ul li.ml-auto {
    width: 100%;  /* Make search full width */
    order: -1;    /* Move to top */
    margin: 0 0 0.5rem 0;
  }

  /* Make search input more compact */
  .stock-search input {
    height: 32px;
    padding: 0.25rem 0.75rem;
  }

  /* Adjust account icon button */
  .nav-icon-button {
    padding: 4px;
  }
  
  .nav-icon-button svg {
    width: 16px;
    height: 16px;
  }

  /* Add subtle division between nav items */
  .nav-content ul li:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding-right: 0.5rem;
  }

  /* Make account section more compact */
  .account-section {
    padding: 0.25rem 0.5rem;
  }

  .user-email {
    font-size: 0.75rem;
  }
}

/* For very small screens */
@media screen and (max-width: 360px) {
  .nav-content ul li a {
    font-size: 0.7rem;
    padding: 0.25rem;
  }
}

@media screen and (max-width: 640px) {
  .dropdown-menu-content {
    position: fixed;
    top: auto;  /* Remove any top positioning */
    bottom: 0;
    left: 0;
    right: 0;
    transform: none !important;  /* Remove any transform */
    margin: 0;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    animation: slideUpMobile 0.2s ease-out;
    z-index: 9999;  /* Ensure it's above other content */
  }

  /* Remove any inherited positioning styles */
  [data-radix-popper-content-wrapper] {
    position: fixed !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    transform: none !important;
    width: 100% !important;
  }

  /* Improve animation */
  @keyframes slideUpMobile {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  /* Make content better spaced */
  .dropdown-menu-content > div {
    padding: 1rem;
  }

  .dropdown-menu-item {
    padding: 1rem;
    justify-content: center;
  }

  /* Add a visual handle */
  .dropdown-menu-content::before {
    content: '';
    display: block;
    width: 32px;
    height: 4px;
    background: #e5e7eb;
    border-radius: 2px;
    margin: 0.5rem auto;
  }
}